/* You can add global styles to this file, and also import other style files */



html, body { height: 100%; }
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: #EFEFEF !important;
}

.h1, h1 {
    color: #26454B;
    font-size: 2em;
    small {
        font-size: 0.5em;
        padding-left: 10px;
    }
}

a {
    color: #26454B;
    text-decoration: none;
    cursor: pointer;
}
.text-right {
    text-align: right;
}

.content {
    padding: 20px 40px 40px 40px;
}
.flex-header {
    display: flex;
    flex-wrap: wrap;

    .header {
        font-weight: bold;
        flex: 1 0 auto;
        h1 {
            margin-bottom: 0;
            font-size: 20px;
        }

        small.version {
            color: #0c6b7b;
            margin-right: 15px;
        }
    }
}
.content-header {
    background-color: #fff;
    padding-bottom: 10px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    .header {
        padding-top: 10px;
    }
    .back-btn {
        margin-left: 10px;
    }
}

.classification-header {
    background-color: #B7E1B8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.3em;
    h3 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        letter-spacing: 0.7px;
        font-style: italic;
    }
}
.color-primary {
    color: #3f51b5;
}

.grey {
    color: #444444;
}

div.loading {
    position: fixed;
    left: calc( 50% - 50px);
    top: calc( 50% - 50px);
}
.button-bar {
    text-align:right;
}

.bg-red {
    background-color: #990707;
}


.bg-info {
    background-color: #3493a6 !important;
}
.btn-info {
    color: #fff;
    background-color: #3493a6;
    border-color: #3493a6;
    &:active, &:hover {
        color: #fff;
        background-color: #549aa8;
        border-color: #ccc;
    }
}

.input-full-w {
    width: 100%;
}

.input-large-w {
  min-width: 625px;
}

.input-med-w {
    min-width: 400px;
}

.hidden {
    display: none;
}

.calculations {
    padding-left: 10px;
    color: #1C765B;
    max-width: 600px;
    word-wrap: break-word;
    table {
        border-collapse: collapse;
        th, td {
            text-align: left;
            border: 1px solid #ccc;
            padding: 0;
        }
    }
}

.float-right {
    float: right;
}

.f-pad {
    margin-right: 10px;
}

.f-action {
    cursor: pointer;
}

.scrollbox {
    max-height: 400px;
    overflow: scroll;
}

.badge {
    &.bg-property {
        background-color: none;
        border: 1px solid #3493a6;
        color: #3493a6;
    }
    &.bg-private {
        background-color: none;
        border: 1px solid #AE0B0B;
        color: #AE0B0B;

    }
}

.account-card {
    margin-top: 20px;
}
.mat-card-content {
    padding: 15px;
}

.flex-bar {
    display: flex;
    justify-content: space-between;
    .flex-div {
        flex: 1 0 auto;
    }
    button.close {
        color: #444444;
    }
    &.with-wrap {
        flex-wrap: wrap;
    }
}
.nested-chart-div {
  overflow: scroll;
}

.pad-15 {
    padding: 15px;
}

.mb-0 {
    margin-bottom: 0;
}

.space-between { justify-content: space-between; }

.alert {
    position: relative;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    &.alert-danger {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }
    &.alert-success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }
    &.alert-warning {
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
    }
    &.alert-info {
        color: #0c5460;
        background-color: #d1ecf1;
        border-color: #bee5eb;
    }
    .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: .75rem 1.25rem;
        color: inherit;
    }
  }

  .progress-overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 9;

    &.hidden { display: none; }
  }

  .flex-header .clear-filters {
    margin: 0 18px;
    position: relative;
    top: -6px;
  }

  .calculations-checkbox {
    padding: 15px 15px 40px;
  }

  .answer-header {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    height: 45px;

  }

  ul.org-list {
    list-style: none;

    b { font-weight: 500; }
  }
